import Axios from 'axios'

//host
import { host } from '../../assets/constants/host';
//help
import checkIsSuccessAction from '../../assets/helps/checkIsSuccessAction';
//type
import * as types from './types'


const apiGetListCheckin = `${host}/biometric/users/get?sort=auto`

export const getListCheckin = () => async dispatch => {    

    try {
        const res = await Axios.get(apiGetListCheckin,{
            headers: {
                license: '5c8b3d99e763df8cfdee7e85'
            }
        });
        const { data } = res;
        console.log(data);
        if (checkIsSuccessAction(data.ErrorCode)) {
            dispatch({
                type: types.GET_LIST_CHECKIN,
                payload: {
                    list: data.Users || [],                    
                }
            })
        }
        else {
            dispatch({
                type: types.GET_LIST_CHECKIN_ERR,
                payload: {
                    ...data
                }
            })
        }
    } catch (error) {
        
    }
};


import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getListCheckin } from '../../redux/listCheckin/action'

import * as style from './index.style'
import RowContent from './RowContent'

const MainPage = () => {

    const dispatch = useDispatch()

    const [data, setData] = useState([])

    const { listCheckin } = useSelector(state => ({
        listCheckin: state.listCheckinReducer
    }))

    useEffect(() => {
        dispatch(getListCheckin())
        setInterval(() => {
            dispatch(getListCheckin())
        }, 5000);
    }, [])

    useEffect(() => {
        if(listCheckin?.list)
        setData(listCheckin?.list)
    }, [listCheckin?.list])

    return (
        <style.Bound>
            <div className="title">
                Lịch sử check-in khuôn mặt
            </div>
            <div className="header-history">
                <p>Thông tin thành viên</p>
                <p>Khu vực</p>
                <p>Trạng thái check-in</p>
            </div>
            <div className="content-history">
                {
                    data?.map((item, index) => (
                        <RowContent key={index} data={item} />
                    ))
                }
            </div>
            {/* <div className="check-history-container">
                
                <div className="check-history">
                    
                    
                </div>
            </div> */}
        </style.Bound>
    )
}

export default MainPage

import styled from 'styled-components'
const Bound = styled.div`
    width: 100%;
    height: calc(100% - 72px);
    display: grid;
    grid-template-columns: 1fr 940px 1fr;
    grid-template-rows: repeat(2, auto) 1fr;
    .title{
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 25px;
        text-transform: uppercase;
        color: #191919;
        padding-top: 60px;
        grid-column: 2 / span 1;
    }
    .header-history{
        display: grid;
        grid-template-columns: 1fr 150px 220px;
        grid-gap: 10px;
        padding: 30px 20px 10px 10px;
        grid-column: 2 / span 1;
        grid-row: 2 / span 1;
        p{
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 15px;
            color: #808080;
           
        }
    }
    .content-history{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        overflow: overlay;
        grid-column: 1 / span 3;
        grid-row: 3 / span 1;
    }
    /* .check-history-container{
        grid-column: 2/span 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .title{
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 25px;
            text-transform: uppercase;
            color: #191919;
            padding-top: 60px;
        }
        .check-history{
            display: flex;
            flex-direction: column;
            width: 100%;
            overflow: hidden;
            .header-history{
                display: grid;
                grid-template-columns: 1fr 150px 220px;
                grid-gap: 10px;
                padding: 30px 0 10px 0;
                p{
                    font-family: 'SVN-Gilroy';
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 15px;
                    color: #808080;
                }
            }
            .content-history{
                display: flex;
                flex-direction: column;
                height: calc(100% - 55px);
                overflow: overlay;
            }
        }
    } */

`
export { Bound }
import React from 'react'
import { convertDateTime } from '../../../assets/helps/tools'
//style
import * as style from './index.style'
const RowContent = ({ data }) => {
    return (
        <style.Bound>
            <div className="info-member-wrap">
                <div className="avatar">
                    <img src={`data:image/png;base64,${data.Avatar}`} alt="avatar-member" />                                    
                </div>
                <div className="info-member">
                    <p>{data.Name}</p>
                    <p>{data.CompanyName}</p>
                </div>
            </div>
            <div className="area">
                {
                    data.CheckIn ?
                        "Sảnh chính"
                        :
                        "-"
                }
            </div>
            <div className= "status-check-wrap">
                <div className={data.CheckIn ? "status" : "status non"}>
                    {
                        data.CheckIn ?
                            <div>Đã check-in</div>
                            :
                            <div>
                                <p>Chưa</p>
                                <p>check-in</p>
                            </div>
                    }
                </div>
                <div className={data.CheckIn ? "time-wrap" : "time-wrap non"}>
                    <p className="time">
                        {data.CheckIn ? convertDateTime(data.CheckIn, "HH:mm") : "--:--"}
                    </p>
                    <p className="date">
                        {data.CheckIn ? convertDateTime(data.CheckIn, "DD/MM/YYYY") : "--/--/----"}
                    </p>
                </div>
            </div>

        </style.Bound>
    )
}

export default RowContent

import * as types from './types'

const intitialState = {
  list: [],
};

const listCheckinReducer = (state = intitialState, action) => {
  switch (action.type) {
    case types.GET_LIST_CHECKIN: {
      return {
        ...state,
        ...action.payload
      }
    }
    case types.RESET_LIST_CHECKIN: {
      return intitialState      
    }
    default:
      return state;
  }
};

export default listCheckinReducer;

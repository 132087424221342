
import { combineReducers } from "redux";
import listCheckinReducer from './listCheckin/listCheckinReducer'

const app = combineReducers({
  listCheckinReducer,
});

const rootReducer = (state, action) => {
   
  return app(state, action);
};

export default rootReducer;


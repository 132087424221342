import styled from 'styled-components'

const Bound = styled.div`
    width: 100%;
    height: 72px;
    display: grid;
    grid-template-columns: 1fr 940px 1fr;
    background: linear-gradient(91.81deg, #007E1D 1.52%, #010800 35.84%);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
    .header-container{
        grid-column: 2/span 1;
        display: flex;
        align-items: center;
        .logo-bank{
            margin-right: 40px;
        }
    }

`
export { Bound }
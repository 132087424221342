import React from 'react'
import './App.css';
//component
import Header from './ui/Header';
import MainPage from './ui/MainPage';

function App() {
  return (
    <React.Fragment>
      <Header />
      <MainPage />
    </React.Fragment>
  );
}

export default App;

import styled from 'styled-components'

const Bound = styled.div`
    display: grid;
    width: 910px;
    grid-template-columns: 1fr 150px 220px;
    grid-gap: 10px;
    height: 96px;    
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
    border-radius: 20px 20px 20px 5px;
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-weight: normal;
    color: #191919;
    margin-bottom: 10px;
    padding: 0 20px 0 10px;
    .info-member-wrap{
        display: flex;
        align-items: center;
        .avatar{
            width: 76px;
            height: 76px;
            object-fit: cover;
            border-radius: 5px;
            margin-right: 21px;
            img{
                width: 76px;
                height: 76px;
                object-fit: cover;
                border-radius: 5px;
            }
        }
        .info-member{
            display: flex;
            flex-direction: column;
            p{  
                font-size: 12px;
                line-height: 13px;
                :first-child{
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 17px;
                    text-transform: uppercase;
                    margin-bottom: 14px;
                }
            }
        }
    }
    .area{        
        font-size: 14px;
        line-height: 15px;
        display: flex;
        align-items: center;
        color: #191919;
    }
    .status-check-wrap{
        display: flex;
        align-items: center;
        /* background: rgba(12, 138, 73, 0.8); */
        /* width: 200px;
        height: 56px;
        border-radius: 10px; */
        .status{
            width: 115px;
            height: 56px;
            display: flex;
            align-items: center;
            background: #0C8A49;
            border-radius: 10px 0px 0px 10px;
            font-weight: bold;
            font-size: 14px;
            line-height: 15px;
            text-transform: uppercase;
            color: #FFFFFF;
            padding-left: 10px;
            &.non{
                background: #9A9A9A;
            }
        }
        .time-wrap{
            width: 85px;
            height: 56px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            background: rgba(12, 138, 73, 0.8);
            border-radius: 0px 10px 10px 0px;
            padding-left: 10px;
            &.non{
                background: rgba(154, 154, 154, 0.8);
            }
            .time{
                font-weight: bold;
                font-size: 16px;
                line-height: 17px;
                color: #FFFFFF;
                margin-bottom: 6px;
            }
            .date{
                font-weight: normal;
                font-size: 12px;
                line-height: 13px;
                color: #FFFFFF;
            }
        }
    }
`
export { Bound }
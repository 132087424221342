import React from 'react'

//style
import * as style from './index.style'
//image
import LogoBankOCB from '../../assets/images/LogoBankOCB'
import LayerBankOCB from '../../assets/images/LayerBankOCB'

const Header = () => {
    return (
        <style.Bound>
            <div className="header-container">
                <LogoBankOCB className="logo-bank" />
                <LayerBankOCB />
            </div>
            
        </style.Bound>
    )
}

export default Header

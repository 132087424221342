import React from 'react'

const LogoBankOCB = (props) => {
    return (
        <svg {...props} width={120} height={48} viewBox="0 0 120 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M56.5327 27.231C53.8385 27.231 51.67 24.7996 51.67 21.7988C51.67 18.798 53.8604 16.3666 56.5327 16.3666C59.2049 16.3666 61.3953 18.798 61.3953 21.7988C61.3953 24.7996 59.2268 27.231 56.5327 27.231ZM56.5327 13.2782C51.4072 13.2782 47.2454 17.1114 47.2454 21.7988C47.2454 26.5081 51.4072 30.3194 56.5327 30.3194C61.6582 30.3194 65.8199 26.4862 65.8199 21.7988C65.8199 17.0895 61.6582 13.2782 56.5327 13.2782Z" fill="white" />
            <path d="M89.3666 16.1913C91.3817 16.1913 92.0607 16.6075 92.0607 18.1189C92.0607 19.4112 91.2722 19.9369 89.4323 19.9369H88.3371L87.22 22.9815H89.5637C91.5789 22.9815 92.6302 23.6386 92.6302 25.1938C92.6302 26.7928 91.4912 27.4061 89.4761 27.4061H86.7819V16.2132L89.3666 16.1913ZM97.011 25.2157C96.9891 24.5805 96.8358 23.9891 96.551 23.4415C96.2663 22.872 95.8501 22.3901 95.2806 21.9958C94.9959 21.7768 94.6454 21.6016 94.273 21.4701C94.8863 21.1635 95.3682 20.7692 95.7187 20.2654C96.2444 19.5207 96.4853 18.6445 96.4415 17.637C96.3758 16.4323 95.9596 15.4685 95.1492 14.7457C94.3607 14.0447 93.1559 13.6724 91.6008 13.6724H82.4449V29.9031H90.4618C91.8636 29.9031 93.0902 29.7279 94.054 29.3774C95.0616 29.0051 95.8282 28.4575 96.332 27.7566C96.8358 27.0556 97.0548 26.2014 97.011 25.2157Z" fill="white" />
            <path d="M80.7584 25.9386C79.7289 26.771 78.3928 27.2529 76.9471 27.2529C73.7053 27.2529 71.0769 24.7996 71.0769 21.7988C71.0769 18.7761 73.7053 16.3447 76.9471 16.3447C78.4147 16.3447 79.7508 16.8485 80.7584 17.659V14.3515C79.4003 13.6725 77.8233 13.2782 76.1367 13.2782C70.9016 13.2782 66.6523 17.0895 66.6523 21.7988C66.6523 26.5081 70.9016 30.3194 76.1367 30.3194C77.8233 30.3194 79.4003 29.9251 80.7584 29.2461V25.9386Z" fill="white" />
            <path d="M49.9396 35.0286C49.9396 34.7658 49.9396 34.481 49.9396 34.2182C49.9396 33.9772 49.9396 33.7363 49.9396 33.4734C49.9396 33.2763 49.9396 32.9916 49.9615 32.663V32.5973H49.3044V32.663C49.3044 32.8163 49.3044 33.0354 49.3263 33.342C49.3263 33.6049 49.3263 33.9115 49.3482 34.2839V34.4591C49.3482 34.6124 49.3482 34.7439 49.3482 34.8534C49.2825 34.722 49.2168 34.5905 49.1292 34.4591C48.9977 34.2182 48.8882 33.9991 48.7568 33.7801C48.6254 33.5611 48.5378 33.3639 48.4282 33.2106L48.4063 33.1887C48.2968 32.9916 48.2092 32.8163 48.1216 32.6411L48.0778 32.5973H47.2673V32.663C47.2673 32.9258 47.2892 33.2106 47.2892 33.4954C47.2892 33.7363 47.2892 33.9991 47.2892 34.262C47.2892 34.5467 47.2892 34.7877 47.2892 35.0505C47.2892 35.3353 47.2892 35.5981 47.2673 35.8391V35.9048H47.9464V35.8391C47.9464 35.5324 47.9245 35.3134 47.9245 35.1162C47.9245 34.8753 47.9244 34.5905 47.9025 34.262V33.9772C47.9025 33.8677 47.9025 33.7582 47.9025 33.6706L49.173 35.9048H49.9615V35.8391C49.9396 35.5762 49.9396 35.3134 49.9396 35.0286Z" fill="white" />
            <path d="M51.8671 34.7658C51.8452 34.941 51.8014 35.0944 51.7138 35.182C51.6262 35.2915 51.5386 35.3353 51.429 35.3353C51.2976 35.3353 51.21 35.2915 51.1005 35.2039C51.0129 35.1163 50.9691 34.9629 50.9472 34.7658C50.9472 34.5687 50.9691 34.3934 51.0348 34.262C51.1005 34.1306 51.1662 34.043 51.2757 33.9992C51.3852 33.9553 51.4948 33.9115 51.6043 33.9115C51.7138 33.9115 51.8014 33.9115 51.8671 33.9334C51.8671 34.3715 51.8671 34.6344 51.8671 34.7658ZM51.6043 33.342C51.429 33.342 51.2538 33.3639 51.1005 33.4297C50.9472 33.4954 50.7938 33.583 50.6624 33.6925C50.531 33.802 50.4434 33.9773 50.3777 34.1525C50.3119 34.3496 50.29 34.5687 50.29 34.8096C50.3119 35.0505 50.3558 35.2696 50.4434 35.4229C50.531 35.5981 50.6624 35.7077 50.7938 35.7953C50.9252 35.861 51.0786 35.9048 51.2538 35.9048C51.4071 35.9048 51.5386 35.861 51.67 35.7953C51.7576 35.7515 51.8233 35.6858 51.889 35.62V35.6419C51.889 35.8391 51.889 36.0143 51.8452 36.1238C51.8233 36.2334 51.7576 36.321 51.6919 36.3648C51.6262 36.4086 51.5167 36.4305 51.3852 36.4305C51.1662 36.4305 50.9033 36.3648 50.6186 36.2553L50.531 36.2114L50.5967 36.8467H50.6405C50.7719 36.8905 50.9033 36.9124 51.0786 36.9343C51.2319 36.9562 51.4071 36.9781 51.5605 36.9781C51.8671 36.9781 52.1081 36.9124 52.2833 36.759C52.4585 36.6057 52.5242 36.3429 52.5242 35.9924C52.5023 35.0944 52.5023 34.2401 52.5242 33.4954V33.4516H52.4804C52.1081 33.3858 51.8233 33.342 51.6043 33.342Z" fill="white" />
            <path d="M53.8166 32.9039L53.9699 33.123H54.4299L54.1671 32.5973H53.4223L53.2033 33.123H53.6633L53.8166 32.9039Z" fill="white" />
            <path d="M54.8023 35.2695C54.8023 35.0286 54.8023 34.8095 54.8023 34.5686C54.8023 34.3058 54.8023 34.1086 54.8023 34.0429C54.7804 33.5829 54.4956 33.3639 53.948 33.3639C53.6852 33.3639 53.4004 33.4077 53.0938 33.5172L53.05 33.5391L53.0062 34.1086L53.0938 34.0648C53.1814 34.021 53.2909 33.9772 53.4442 33.9334C53.5757 33.8896 53.7071 33.8677 53.8385 33.8677C54.1233 33.8677 54.1671 33.9772 54.1671 34.0648C54.1671 34.1743 54.1233 34.2181 54.0795 34.24C53.9918 34.2839 53.8166 34.3277 53.5757 34.4153C53.3128 34.5029 53.1157 34.5905 53.0062 34.7219C52.8747 34.8534 52.809 35.0286 52.8309 35.2476C52.8309 35.4667 52.9185 35.6419 53.05 35.7733C53.1814 35.9047 53.3785 35.9705 53.6414 35.9705C53.7071 35.9705 53.8385 35.9485 54.0137 35.9266H54.0575L54.1671 35.3571L54.0575 35.4229C54.0137 35.4448 53.9261 35.4886 53.7728 35.4886C53.5319 35.4667 53.51 35.3571 53.51 35.2695C53.51 35.16 53.5319 35.0943 53.5976 35.0505C53.6852 34.9848 53.8166 34.941 53.9699 34.8753C54.0795 34.8314 54.1671 34.7876 54.2328 34.7657C54.2328 35.2695 54.2328 35.6419 54.2328 35.8828V35.9485H54.8899V35.8828C54.8242 35.6638 54.8023 35.4667 54.8023 35.2695Z" fill="white" />
            <path d="M57.3869 35.4886C57.3869 35.401 57.3869 35.2257 57.3869 34.9848C57.3869 34.7876 57.3869 34.5905 57.3869 34.4591C57.3869 34.3715 57.3869 34.262 57.3869 34.1524C57.3869 33.9991 57.3431 33.8677 57.2993 33.7582C57.2336 33.6486 57.146 33.5391 57.0365 33.4734C56.9269 33.4077 56.7517 33.3639 56.5765 33.3639C56.2698 33.3639 56.0289 33.4734 55.8756 33.6705V33.4077H55.2184V33.4734C55.2403 34.24 55.2403 35.0286 55.2184 35.839V35.9047H55.8975V35.839C55.8975 35.7733 55.8975 35.6857 55.8975 35.5981V35.4886C55.8975 35.3133 55.8975 35.2038 55.8975 35.1162C55.8975 35.0067 55.8975 34.8972 55.8975 34.8095C55.8975 34.7219 55.8975 34.6343 55.9194 34.5686C55.9632 34.1962 56.1165 33.9991 56.3793 33.9991C56.6203 33.9991 56.7517 34.1524 56.7517 34.4372C56.7736 34.8314 56.7517 35.2914 56.7079 35.8171V35.8828H57.4088V35.8171C57.3869 35.6857 57.3869 35.5762 57.3869 35.4886Z" fill="white" />
            <path d="M61.6363 32.663V32.5973H60.9135V32.663C60.9354 32.9258 60.9354 33.3201 60.9354 33.8677H59.7306C59.7306 33.7363 59.7306 33.5611 59.7306 33.342C59.7306 33.1011 59.7306 32.8601 59.7306 32.663V32.5973H59.0078V32.663C59.0297 33.1668 59.0516 33.6925 59.0516 34.2401C59.0516 34.7877 59.0516 35.3134 59.0297 35.8391V35.9048H59.7744V35.7953C59.7744 35.7295 59.7744 35.6419 59.7744 35.5324C59.7744 35.4886 59.7744 35.4229 59.7744 35.3572C59.7744 35.2915 59.7744 35.2039 59.7744 35.1162V35.0724C59.7744 34.941 59.7744 34.8315 59.7744 34.7439C59.7744 34.6344 59.7744 34.5686 59.7744 34.481H60.9792C60.9792 34.722 60.9792 34.941 61.0011 35.16C61.0011 35.401 61.0011 35.62 61.0011 35.8391V35.9048H61.7458V35.8391C61.6363 34.9848 61.6363 33.9115 61.6363 32.663Z" fill="white" />
            <path d="M63.3667 33.1668L63.1258 32.5973H62.3591L62.841 33.1668H63.3667Z" fill="white" />
            <path d="M63.958 35.2695C63.958 35.0724 63.958 34.8315 63.958 34.5686C63.958 34.4153 63.958 34.3058 63.958 34.2181C63.958 34.1305 63.958 34.0648 63.958 34.0429C63.9361 33.5829 63.6514 33.3639 63.1038 33.3639C62.8409 33.3639 62.5562 33.4077 62.2495 33.5172L62.2057 33.5391L62.1619 34.1086L62.2495 34.0648C62.3372 34.021 62.4467 33.9772 62.6 33.9334C62.7314 33.8896 62.8629 33.8677 62.9943 33.8677C63.279 33.8677 63.3228 33.9772 63.3228 34.0648C63.3228 34.1743 63.279 34.2181 63.2352 34.24C63.1476 34.2839 62.9724 34.3277 62.7314 34.4153C62.4686 34.5029 62.2714 34.5905 62.1619 34.7219C62.0305 34.8534 61.9648 35.0286 61.9867 35.2476C61.9867 35.4667 62.0743 35.6419 62.2057 35.7733C62.3372 35.9047 62.5343 35.9705 62.7971 35.9705C62.8848 35.9705 62.9943 35.9485 63.1695 35.9266H63.2133L63.3228 35.3571L63.2133 35.4229C63.1695 35.4448 63.0819 35.4886 62.9286 35.4886C62.6876 35.4667 62.6657 35.3571 62.6438 35.2695C62.6438 35.16 62.6657 35.0943 62.7533 35.0505C62.8409 34.9848 62.9724 34.941 63.1257 34.8753C63.2352 34.8314 63.3228 34.8095 63.3885 34.7657C63.3885 35.2914 63.3885 35.6419 63.3885 35.8828V35.9485H64.0457V35.8828C63.9799 35.6419 63.958 35.4667 63.958 35.2695Z" fill="white" />
            <path d="M66.5427 35.4886C66.5427 35.4229 66.5427 35.2695 66.5427 34.9848C66.5427 34.8095 66.5427 34.6562 66.5427 34.5467V34.4591C66.5427 34.3496 66.5427 34.24 66.5427 34.1524C66.5427 33.9991 66.4989 33.8677 66.4551 33.7582C66.4113 33.6486 66.3018 33.5391 66.1923 33.4734C66.0609 33.4077 65.9075 33.3639 65.7323 33.3639C65.4257 33.3639 65.1847 33.4734 65.0314 33.6705V33.4077H64.3743V33.4734C64.3962 34.2181 64.3962 35.0067 64.3743 35.839V35.9047H65.0533V35.839C65.0533 35.7952 65.0533 35.7733 65.0533 35.7295C65.0533 35.6638 65.0533 35.5762 65.0533 35.4886C65.0533 35.3352 65.0533 35.2257 65.0533 35.1162C65.0533 35.0067 65.0533 34.9191 65.0533 34.8095C65.0533 34.7 65.0533 34.6343 65.0752 34.5686C65.119 34.1962 65.2723 33.9991 65.5352 33.9991C65.7761 33.9991 65.9075 34.1524 65.9075 34.4372C65.9294 34.8314 65.9075 35.2914 65.8637 35.8171V35.8828H66.5647V35.8171C66.5428 35.6857 66.5427 35.5762 66.5427 35.4886Z" fill="white" />
            <path d="M68.3608 34.7658C68.3389 34.941 68.2951 35.0944 68.2074 35.182C68.1198 35.2915 68.0322 35.3353 67.9227 35.3353C67.7913 35.3353 67.7037 35.2915 67.616 35.2039C67.5284 35.1163 67.4846 34.9629 67.4627 34.7658C67.4627 34.5687 67.4846 34.3934 67.5503 34.262C67.616 34.1306 67.7037 34.043 67.7913 33.9992C67.9008 33.9553 68.0103 33.9115 68.1198 33.9115C68.2294 33.9115 68.317 33.9115 68.3827 33.9334C68.3827 34.3496 68.3827 34.6344 68.3608 34.7658ZM68.1198 33.342C67.9446 33.342 67.7694 33.3639 67.616 33.4297C67.4627 33.4954 67.3094 33.583 67.178 33.6925C67.0465 33.802 66.9589 33.9773 66.8932 34.1525C66.8275 34.3496 66.8056 34.5687 66.8056 34.8096C66.8275 35.0505 66.8713 35.2696 66.9589 35.4229C67.0465 35.5981 67.178 35.7077 67.3094 35.7953C67.4627 35.861 67.616 35.9048 67.7694 35.9048C67.9227 35.9048 68.0541 35.861 68.1855 35.7953C68.2732 35.7515 68.3389 35.6858 68.4046 35.62V35.6419C68.4046 35.8391 68.4046 36.0143 68.3608 36.1238C68.3389 36.2334 68.2732 36.321 68.2074 36.3648C68.1417 36.4086 68.0322 36.4305 67.9008 36.4305C67.6818 36.4305 67.4189 36.3648 67.1342 36.2553L67.0465 36.2114L67.1123 36.8467H67.1561C67.2875 36.8905 67.4189 36.9124 67.5941 36.9343C67.7694 36.9562 67.9227 36.9781 68.076 36.9781C68.3827 36.9781 68.6455 36.9124 68.7989 36.759C68.9741 36.6057 69.0398 36.3429 69.0398 35.9924C69.0179 35.0724 69.0179 34.2401 69.0398 33.4954V33.4516H68.996C68.6236 33.3858 68.3389 33.342 68.1198 33.342Z" fill="white" />
            <path d="M75.2167 35.3572C75.2167 35.16 75.2167 34.9629 75.2167 34.722C75.2167 34.481 75.2167 34.2839 75.2167 34.1744C75.1948 33.9115 75.1291 33.6925 74.9757 33.5611C74.8224 33.4296 74.6253 33.3639 74.3843 33.3639C74.1872 33.3639 74.012 33.4077 73.8805 33.4734C73.7929 33.5173 73.7272 33.583 73.6834 33.6706C73.6834 33.3201 73.6834 32.9697 73.6834 32.663V32.5973H73.0044V32.663C73.0263 33.7801 73.0482 34.8315 73.0263 35.8391V35.9048H73.7053V35.8391C73.6834 35.401 73.6834 35.0286 73.7053 34.7439C73.7053 34.5029 73.771 34.3058 73.8587 34.1963C73.9463 34.0868 74.0558 34.021 74.1872 34.021C74.3186 34.021 74.4062 34.0648 74.472 34.1306C74.5377 34.1963 74.5815 34.3277 74.5815 34.481C74.6034 34.8972 74.5815 35.3572 74.5377 35.861V35.9267H75.2386V35.861C75.2167 35.6638 75.2167 35.5105 75.2167 35.3572Z" fill="white" />
            <path d="M82.7297 35.4886C82.7297 35.401 82.7297 35.2257 82.7297 34.9848C82.7297 34.7219 82.7297 34.5686 82.7297 34.4591C82.7297 34.3277 82.7297 34.24 82.7297 34.1524C82.7297 33.9991 82.6859 33.8677 82.6421 33.7582C82.5764 33.6486 82.4888 33.5391 82.3792 33.4734C82.2478 33.4077 82.1164 33.3639 81.9193 33.3639C81.6126 33.3639 81.3936 33.4734 81.2183 33.6705V33.4077H80.5612V33.4734C80.5831 34.24 80.5831 35.0286 80.5612 35.839V35.9047H81.2402V35.839C81.2402 35.7952 81.2402 35.7514 81.2402 35.7076C81.2402 35.6419 81.2402 35.5762 81.2402 35.5105C81.2402 35.3571 81.2402 35.2476 81.2402 35.1381C81.2402 35.0067 81.2402 34.9191 81.2402 34.8315C81.2402 34.7438 81.2402 34.6562 81.2621 34.5905C81.306 34.2181 81.4593 34.021 81.7221 34.021C81.9631 34.021 82.0945 34.1743 82.0945 34.4591C82.1164 34.8534 82.0945 35.3133 82.0507 35.839V35.9047H82.7516V35.839C82.7297 35.7076 82.7297 35.5762 82.7297 35.4886Z" fill="white" />
            <path d="M84.5696 34.7658C84.5477 34.941 84.5039 35.0944 84.4163 35.182C84.3287 35.2696 84.2411 35.3353 84.1316 35.3353C84.0001 35.3353 83.9125 35.2915 83.803 35.2039C83.7154 35.1163 83.6716 34.9629 83.6497 34.7658C83.6497 34.5687 83.6716 34.3934 83.7373 34.262C83.803 34.1306 83.8906 34.043 83.9782 33.9992C84.0878 33.9553 84.1973 33.9115 84.3068 33.9115C84.4163 33.9115 84.5039 33.9115 84.5696 33.9334C84.5696 34.3715 84.5696 34.6563 84.5696 34.7658ZM84.3287 33.342C84.1535 33.342 83.9782 33.3639 83.8249 33.4297C83.6716 33.4954 83.5183 33.583 83.3868 33.6925C83.2554 33.802 83.1678 33.9773 83.1021 34.1525C83.0364 34.3496 83.0145 34.5687 83.0145 34.8096C83.0364 35.0505 83.0802 35.2696 83.1678 35.4229C83.2554 35.5762 83.3868 35.7077 83.5183 35.7953C83.6497 35.861 83.803 35.9048 83.9782 35.9048C84.1316 35.9048 84.263 35.861 84.3944 35.7953C84.482 35.7515 84.5477 35.6858 84.6134 35.62V35.6419C84.6134 35.8391 84.6134 36.0143 84.5696 36.1238C84.5477 36.2334 84.482 36.321 84.4163 36.3648C84.3506 36.4086 84.2411 36.4305 84.1097 36.4305C83.8906 36.4305 83.6278 36.3648 83.343 36.2553L83.2554 36.2114L83.3211 36.8467H83.3649C83.4744 36.8905 83.6278 36.9124 83.803 36.9343C83.9563 36.9562 84.1316 36.9781 84.2849 36.9781C84.5915 36.9781 84.8325 36.9124 85.0077 36.759C85.183 36.6057 85.2487 36.3429 85.2487 35.9924C85.2268 35.0505 85.2268 34.2401 85.2487 33.4954V33.4516H85.2049C84.8325 33.3858 84.5258 33.342 84.3287 33.342Z" fill="white" />
            <path d="M88.8409 34.7001C88.7752 34.8753 88.6876 35.0067 88.5562 35.1162C88.4247 35.2258 88.2276 35.2696 87.9867 35.2696H87.6143V34.5029H87.7676C87.9648 34.5029 88.1181 34.5029 88.2057 34.5029H88.2714L88.2933 33.9115H87.6143V33.1887H88.2276C88.3809 33.1887 88.5124 33.2325 88.6219 33.3201C88.7314 33.4077 88.7971 33.5173 88.8409 33.6487C88.8847 33.7801 88.9066 33.9334 88.9285 34.0868C88.9066 34.3058 88.8847 34.5029 88.8409 34.7001ZM89.1914 32.9697C88.9504 32.7287 88.6438 32.5973 88.2495 32.5973H86.8915V32.663C86.9134 33.1668 86.9134 33.583 86.9134 33.9553H86.5629L86.541 34.5248H86.9353C86.9353 35.0067 86.9353 35.4448 86.9134 35.8391V35.9048H88.1181C88.4686 35.9048 88.7533 35.8172 88.9723 35.6638C89.1914 35.5105 89.3666 35.2915 89.4542 35.0286C89.5418 34.7658 89.5857 34.4591 89.5857 34.1087C89.5637 33.583 89.4323 33.2106 89.1914 32.9697Z" fill="white" />
            <path d="M91.2941 35.16C91.2284 35.3133 91.097 35.379 90.9437 35.379C90.7904 35.379 90.6808 35.3133 90.5932 35.2038C90.5056 35.0724 90.4399 34.9191 90.4399 34.7438C90.4399 34.481 90.4837 34.2839 90.5713 34.1305C90.6589 33.9991 90.7684 33.9334 90.9218 33.9334C91.0751 33.9334 91.1846 33.9991 91.2722 34.1086C91.3599 34.24 91.4037 34.3934 91.4037 34.5905C91.4037 34.8095 91.3598 35.0067 91.2941 35.16ZM91.9074 33.9553C91.8198 33.7582 91.6884 33.6048 91.5132 33.5172C91.3379 33.4077 91.1408 33.3639 90.8999 33.3639C90.6589 33.3639 90.4399 33.4296 90.2647 33.5391C90.0894 33.6486 89.958 33.8239 89.8704 34.021C89.7828 34.2181 89.7609 34.4591 89.7609 34.7219C89.7609 34.8753 89.8047 35.0286 89.8485 35.1819C89.8923 35.3352 89.9799 35.4667 90.0675 35.5762C90.1551 35.6857 90.2866 35.7733 90.4399 35.8609C90.5932 35.9266 90.7465 35.9705 90.9218 35.9705C91.1846 35.9705 91.4037 35.9047 91.5789 35.7733C91.7541 35.6419 91.8855 35.4667 91.9513 35.2695C92.017 35.0724 92.0608 34.8534 92.0389 34.6343C92.0389 34.3715 91.9951 34.1524 91.9074 33.9553Z" fill="white" />
            <path d="M90.856 32.9039L91.0313 33.123H91.4694L91.2065 32.5973H90.4618L90.2427 33.123H90.7027L90.856 32.9039Z" fill="white" />
            <path d="M94.514 35.4886C94.514 35.401 94.514 35.2476 94.514 34.9848V34.8753C94.514 34.7 94.514 34.5467 94.514 34.4591C94.514 34.3496 94.514 34.24 94.514 34.1524C94.514 33.9991 94.4702 33.8677 94.4264 33.7582C94.3607 33.6486 94.2731 33.5391 94.1635 33.4734C94.0321 33.4077 93.8788 33.3639 93.7036 33.3639C93.3969 33.3639 93.156 33.4734 93.0026 33.6705V33.4077H92.3455V33.4734C92.3674 34.24 92.3674 35.0286 92.3455 35.839V35.9047H93.0245V35.839C93.0245 35.7514 93.0245 35.6419 93.0245 35.4886C93.0245 35.3571 93.0245 35.2257 93.0245 35.1162C93.0245 35.0067 93.0245 34.9191 93.0245 34.8095C93.0245 34.7219 93.0245 34.6343 93.0464 34.5686C93.0902 34.1962 93.2436 33.9991 93.5064 33.9991C93.7474 33.9991 93.8788 34.1524 93.8788 34.4372C93.9007 34.8314 93.8788 35.2914 93.835 35.8171V35.8828H94.5359V35.8171C94.514 35.6857 94.514 35.5762 94.514 35.4886Z" fill="white" />
            <path d="M96.3539 34.7657C96.332 34.941 96.2882 35.0943 96.2006 35.1819C96.113 35.2695 96.0254 35.3352 95.9159 35.3352C95.7844 35.3352 95.6968 35.2914 95.6092 35.2038C95.5216 35.1162 95.4778 34.9629 95.4559 34.7657C95.4559 34.5686 95.4778 34.3934 95.5435 34.262C95.6092 34.1305 95.6968 34.0429 95.7844 33.9991C95.8939 33.9553 96.0035 33.9115 96.113 33.9115C96.2225 33.9115 96.3101 33.9115 96.3758 33.9334C96.3758 34.3715 96.3539 34.6343 96.3539 34.7657ZM97.011 33.4953H96.9672C96.5949 33.4077 96.3101 33.3639 96.0911 33.3639C95.9159 33.3639 95.7406 33.3858 95.5873 33.4515C95.434 33.5172 95.2806 33.6048 95.1492 33.7144C95.0178 33.8239 94.9302 33.9991 94.8645 34.1743C94.7988 34.3715 94.7769 34.5905 94.7769 34.8315C94.7769 35.0724 94.8426 35.2914 94.9302 35.4448C95.0178 35.62 95.1492 35.7295 95.2806 35.8171C95.434 35.8828 95.5654 35.9266 95.7406 35.9266C95.894 35.9266 96.0254 35.8828 96.1568 35.8171C96.2444 35.7733 96.3101 35.7076 96.3758 35.6419V35.6638C96.3758 35.8609 96.3758 36.0362 96.332 36.1457C96.3101 36.2552 96.2444 36.3428 96.1787 36.3866C96.113 36.4304 96.0035 36.4523 95.872 36.4523C95.653 36.4523 95.3902 36.3866 95.1054 36.2771L95.0178 36.2333L95.0835 36.8685H95.1273C95.2587 36.9123 95.3902 36.9342 95.5654 36.9561C95.7406 36.978 95.894 36.9999 96.0473 36.9999C96.3539 36.9999 96.5949 36.9342 96.7701 36.7809C96.9453 36.6276 97.011 36.3647 97.011 36.0143C96.9891 35.1162 96.9891 34.2619 97.011 33.5172V33.4953Z" fill="white" />
            <path d="M77.6699 33.8896C77.8233 33.8458 78.0423 33.6925 78.1299 33.5173C78.1956 33.342 78.1299 33.123 77.9766 33.0354C77.889 32.9916 77.8233 32.9478 77.7357 32.9478C77.6261 32.9478 77.5166 32.9916 77.4071 33.0573L77.3633 33.0792L77.4509 33.3201L77.5166 33.2763C77.5604 33.2544 77.6042 33.2325 77.648 33.2325C77.6699 33.2325 77.6919 33.2325 77.6919 33.2544C77.7576 33.2982 77.7576 33.3858 77.7357 33.4296C77.7357 33.4296 77.7357 33.4515 77.6919 33.4954C77.6919 33.4734 77.6919 33.4734 77.6919 33.4515V33.3858H76.9909V33.4515C76.9909 33.7582 77.0128 34.481 76.9909 34.7658C76.969 34.9848 76.8814 35.2696 76.5528 35.2696C76.3119 35.2696 76.2024 35.1162 76.1805 34.8315C76.1586 34.5686 76.1805 33.6706 76.1805 33.4515V33.3858H75.5015V33.4515C75.5234 33.9115 75.5234 34.2839 75.5234 34.5686C75.5234 34.7877 75.5234 34.9629 75.5234 35.1381C75.5453 35.62 75.8519 35.9048 76.3338 35.9048C76.6405 35.9048 76.8595 35.8172 77.0128 35.5981L77.0347 35.8391H77.6699V35.7734C77.6699 35.2258 77.648 34.5905 77.6699 33.8896Z" fill="white" />
            <path d="M79.4441 35.2696C79.3784 35.3572 79.2689 35.3791 79.1594 35.3791C78.8089 35.3791 78.6775 35.0286 78.6556 34.7439C78.6337 34.4372 78.6994 34.2182 78.8308 34.0868C78.8965 33.9991 79.006 33.9553 79.1375 33.9553C79.5536 33.9553 79.6194 34.4153 79.6194 34.6124C79.6194 34.8972 79.5536 35.1381 79.4441 35.2696ZM79.9698 33.7801C80.0574 33.7363 80.1669 33.6268 80.2108 33.5173C80.2765 33.342 80.2108 33.123 80.0574 33.0354C79.9698 32.9916 79.9041 32.9478 79.8165 32.9478C79.707 32.9478 79.5974 32.9916 79.4879 33.0573L79.4441 33.0792L79.5317 33.3201L79.5974 33.2763C79.6413 33.2544 79.6851 33.2325 79.7289 33.2325C79.7508 33.2325 79.7727 33.2325 79.7727 33.2544C79.8384 33.2982 79.8384 33.3858 79.8165 33.4296C79.8165 33.4515 79.7946 33.4734 79.7289 33.5173C79.5536 33.4077 79.3346 33.342 79.1156 33.342C78.787 33.342 78.5242 33.4515 78.327 33.6706C78.108 33.9115 77.9766 34.262 77.9985 34.7001C78.0204 35.2696 78.3489 35.9267 79.1813 35.9267C79.5098 35.9267 79.7727 35.8172 79.9698 35.62C80.2984 35.2696 80.2984 34.7439 80.2984 34.5905C80.2546 34.2839 80.145 33.9991 79.9698 33.7801Z" fill="white" />
            <path d="M72.7634 33.4954C72.7634 33.342 72.7196 33.1887 72.6539 33.0573C72.5882 32.9258 72.5006 32.8163 72.3911 32.7287C72.2815 32.6411 72.1501 32.5973 71.9968 32.5973H70.6826V32.663C70.7045 32.9697 70.7045 33.2544 70.7045 33.5173C70.7045 33.802 70.7045 34.0429 70.7264 34.2839C70.7264 34.5248 70.7264 34.7877 70.7264 35.0943C70.7264 35.3791 70.7264 35.6419 70.7264 35.861V35.9267H71.4054V35.861C71.4054 35.6419 71.3616 33.6706 71.3397 33.4734V33.2106C71.4711 33.1887 71.5806 33.1887 71.712 33.1887C71.8435 33.1887 71.9311 33.2106 71.9968 33.2544C72.0625 33.2982 72.0844 33.3858 72.0844 33.5173C72.0844 33.6487 72.0625 33.7801 71.9968 33.8677C71.8216 34.0868 71.5368 34.021 71.4273 33.9991L71.5587 34.5686C71.5587 34.5686 72.0187 34.6782 72.3692 34.4372C72.5225 34.3277 72.632 34.1963 72.6977 34.021C72.7415 33.8458 72.7634 33.6706 72.7634 33.4954Z" fill="white" />
            <path d="M23.0417 22.9597C23.3264 25.5005 24.4873 27.7785 26.2177 29.4651C26.0206 28.9394 25.9549 28.3918 26.0863 27.8442C26.4148 26.3329 27.97 25.2596 30.1385 24.9529V17.6151C24.5092 16.6514 22.6912 20.2217 23.0417 22.9597Z" fill="#E39717" />
            <path d="M44.4417 20.5502C44.157 18.0094 42.996 15.7314 41.2656 14.0448C41.4628 14.5705 41.5285 15.1181 41.3971 15.6657C41.0685 17.1771 39.5133 18.2503 37.3448 18.557V25.8948C42.9741 26.8367 44.7922 23.2882 44.4417 20.5502Z" fill="#E39717" />
            <path d="M23.2388 19.3674C24.7063 17.3741 27.3348 16.2132 32.1975 17.5713C40.7181 19.9807 43.456 14.3952 38.5934 12.1391C37.1258 11.3944 35.483 11.0001 33.7526 11.0001C28.6052 10.9782 24.334 14.5704 23.2388 19.3674Z" fill="#E39717" />
            <path d="M44.2446 24.1425C42.777 26.1357 40.1486 27.2966 35.2859 25.9386C26.7653 23.5292 24.0273 29.1146 28.89 31.3707C30.3356 32.1155 32.0003 32.5097 33.7307 32.5097C38.8562 32.5316 43.1494 28.9394 44.2446 24.1425Z" fill="#E39717" />
        </svg>
    )
}

export default LogoBankOCB
